%input {
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 400;
  line-height: 90px;
  padding: 0 35px;
  border: 2px solid transparent;
  border-radius: $borderRadius;
  background-color: $colorBlack;
  margin-top: 30px;
  box-sizing: border-box;
  color: $mainColorLightFont;
  font-size: 40px;

  &:first-child {
    margin-top: 0;
  }
  &::placeholder {
    color: rgba(196, 196, 196, 0.5);
    font-size: 40px;
    line-height: 90px;
    font-family: 'Exo2';
    font-style: normal;
    font-weight: 400;
  }

  &.error {
    border-color: $colorRed;
  }

  //&:invalid:required {
  //  background-image: linear-gradient(to right, pink, lightgreen);
  //}
}

.input {
  @extend %input;
}

.text-area {
  @extend %input;
  min-width: 100%;
  max-width: 100%;
  resize: vertical;
  min-height: 90px;
}

.input-wrapper {
  position: relative;
  margin-top: 20px;
  padding-bottom: 25px;

  &:first-child {
    margin-top: 0;
  }

  .error-message {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    color: $colorRed;
    font-size: 24px;
  }
}

//label Extra small devices (portrait phones, less than 576px)
@media (max-width: $screen-xs-max) {
  %input {
    line-height: 40px;
    margin-top: 20px;
    padding: 20px 24px;
    font-size: 30px;

    &:first-child {
      margin-top: 0;
    }
    &::placeholder {
      font-size: 30px;
      line-height: 40px;
    }
  }

  .text-area {
    min-height: 150px;
  }
}

