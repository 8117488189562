.block {
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  //align-items: center;
  overflow: hidden;
  //position: sticky!important;
  //top: 0;

  &-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    //align-items: center;
  }

  &.border {
    border-radius: 0 0 $cardRadius $cardRadius;
  }
}

//label z-index
$z-index: (
        10 : 10,
        9 : 9,
        8 : 8,
        7 : 7,
        6 : 6,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
  transform:translateZ(#{z-index($key)}px);
  -webkit-transform:translateZ(#{z-index($key)}px);
  -moz-transform:translateZ(#{z-index($key)}px);
  -o-transform:translateZ(#{z-index($key)}px);
}

@for $i from 6 through 11 {
  .z-#{$i}{
    @include z-index($i);
  }
}

.space-between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.container {
  display: flex;
  box-sizing: border-box;
  //todo
  width: 1140px;
  margin: 0 auto;

  &-fluid {
    padding: 0 !important;
  }
}

#videoDesktop {
  display: block;
  max-height: 100%;
}
#videoMobile {
  display: none;
}

//label STICKY
.sticky {
  position: sticky;
  bottom: 0%;
  padding-top: 100px;

  &-features {
    background-color: $colorRed;
  }

  &-proposal {
    background-color: $colorWhite;
  }

  &-contact {
    background-color: $colorLightBlack;
  }

  //todo hack to remove an artifact in the form of a strip of size 1px
  padding-bottom: 1px;
  margin-bottom: -1px;
}

.section-head-bg-extension {
  position: absolute;
  top: -$cardRadius;
  left: 0;
  right: 0;
  height: $cardRadius;

  &.features {
    background-color: $colorRed;
  }

  &.proposal {
    background-color: $colorWhite;
  }

  &.contact {
    background-color: $colorLightBlack;
  }
}

//label MAIN BLOCK
.main-block {
  $padding:80px;
  position: relative;
  background-color: $colorBlack;
  //padding-bottom: 100px;
  padding: $padding 0;
  //min-height: calc(100vh - (#{$padding} * 2));

  .overlay {
    background: #000000cc;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .button-container {
    margin-top: 20px;
  }

  .screen {
    &-container {
      display: flex;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      //padding: $padding 0;
    }

  }

  .title-wrapper {
    font-size: 100px;
    font-weight: 700;
    line-height: 120px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    //margin-top: 85px;
    margin-bottom: 15px;

    .title {
      color: $colorRed;
    }

    .description {
      color: $mainColorLightFont;
      text-align: center;
    }

    &.mobile {
      display: none;
    }
  }

  .preferences {
    font-size: 32px;
    color: $mainColorLightFont;
    margin-top: 24px;
    text-align: center;

    .item {
      //display: inline-block;
      position: relative;
      margin-bottom: 32px;
      padding-left: 20px;

      &-wrapper {
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }

      &:before {
        content: '\e90b';
        font-family: 'icostusan';
        font-size: 13px;
        color: $mainColorLightFont;
        position: absolute;
        top: 15px;
        //top: calc(50% - (12px / 2));
        left: 0;
        opacity: 0.5;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .link-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    line-height: 21px;
    color: $mainColorLightFont;
    //margin-top: 145px;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg) scale(19);
      right: 110px;
    }
  }

  .play-button {
    position: relative;
    margin-top: 20px;
    overflow: hidden;
    border: none;
    &:hover {
      .triangle {
        //animation:spin 0.3s linear both;
      }
    }

    .title {
      position: relative;
      z-index: 1;
    }

    .triangle {
      position: absolute;
      width: 55px;
      top: 10px;
      right: 20px;
      transition: all 5s;
      //clip-path: polygon(0px 208px, 146.5px 207px, 147px 141.2px);
      //animation:spin 5s linear forwards;

    }
  }
}

.video-container {
  flex: 1;
  //position: relative;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.2;
  &-inner {
    height: 100%;
    align-items: flex-start;
    display: flex;
    justify-content: center;
  }
}

.screen-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  padding-bottom: 100px;
  //background-color: #f0f;
}

video {
  //position: absolute;
  //max-width: 100%;
}

//label FEATURES BLOCK
.features-block {
  //z-index: 4;
  background-color: $colorRed;
  padding: 0 0 70px 0;
  position: relative;
  min-height: calc(100vh - 417px);

  //&:before {
  //  content: '\e902';
  //
  //}

  &-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    margin-bottom: 120px;

    & + &-text-wrapper {
      margin-top: 0;
    }
  }

  &-text {
    span {
      //color: $mainColorLightFont;
      //animation: topToBottom 4.5s linear infinite 0s;
      color: $mainColorLightFont;
      //opacity: 0;
      //overflow: hidden;
      //position: absolute;
    }
  }

  &-description {
    width: 660px;
    text-align: center;
    margin-top: 43px;
  }
}

.icostusan {
  position: absolute;

  &:before {
    color: rgba(256, 256, 256, 0.2);
  }

  &.rec {
    bottom: 57%;
    right: 12%;
    font-size: 40px;
  }

  &.phone {
    bottom: 13%;
    right: 32%;
    font-size: 40px;
  }

  &.user {
    bottom: 13%;
    left: 14%;
    font-size: 40px;
  }

  &.camera {
    top: 68%;
    left: 40%;
    font-size: 40px;
  }

  &.chat {
    top: 70%;
    right: 5%;
    font-size: 40px;
  }

  &.hand {
    bottom: 18%;
    right: 8%;
    font-size: 40px;
  }

  &.mic {
    top: 50%;
    left: 12%;
    font-size: 40px;
  }

  &.share {
    bottom: 60%;
    left: 7%;
    font-size: 40px;
  }
}


//label PROPOSAL BLOCK
.proposal-block {
  position: relative;
  background-color: $colorWhite;
  min-height: calc(100vh - 380px);

  &:before {
    content: '\e907';
    font-family: 'icostusan';
    font-size: 407px;
    position: absolute;
    bottom: -50px;
    left: -47px;
    transform: rotate(-15deg);
    opacity: 0.07;
    color: #261362;
  }

  &-heading {

    color: $colorRed;
  }

  &-price {
    font-size: 120px;
    font-weight: 700;
    color: $colorRed;
    margin-top: 40px;
    padding-bottom: 20px;
  }

  .description {
    display: block;
    width: 860px;
    text-align: center;
    margin-top: 90px;
  }

  &-button {
    margin-top: 55px;
    font-weight: 700;
  }
}

//label CONTACT BLOCK
.contact-block {
  position: relative;
  //z-index: 2;
  padding: 0 0 70px;
  justify-content: center;

  background-color: $colorLightBlack;

  &-title {
    display: block;
    color: $mainColorLightFont;
    margin-bottom: 38px;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .send-button {
      font-family: 'Exo2';
      font-weight: 700;
      background-color: transparent;
      border-color: $colorWhite;
      color: $mainColorLightFont;
      width: auto;
    }
  }

  .successful-sending {
    text-align: center;
    color: $mainColorLightFont;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
  }

  input, textarea {
    width: 100%;
  }
}

//label FOOTER BLOCK
.footer-block {
  z-index: 1;
  background-color: $colorDarkGray;
  position: relative;
  padding: 300px 0 150px;

  &:after {
    content: '\e907';
    font-family: 'icostusan';
    font-size: 407px;
    position: absolute;
    bottom: -50px;
    right: -47px;
    transform: rotate(-15deg);
    color: $mainColorLightFont;
    opacity: 0.07;
  }

  .tagline {
    position: absolute;
    left: -30px;
    right: -30px;
    top: -20px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 2.5em;
    font-weight: 700;
    color: $colorGray;
    text-transform: uppercase;
  }

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .contact-wrapper {
    .logo {
      margin-bottom: 42px;
    }

    .description {
      margin-top: 42px;
      max-width: 600px;
      color: #797979;
    }
  }

  .copyright {
    font-size: 18px;
    color: #797979;
  }
}

.features-sliding-vertical-item {
  display: inline-block;
  &.hide {
    //opacity: 0;
    display: none;
  }
}