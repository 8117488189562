@font-face {
  font-family: 'icostusan';
  src:  url('fonts/icostusan.eot?dw7joc');
  src:  url('fonts/icostusan.eot?dw7joc#iefix') format('embedded-opentype'),
    url('fonts/icostusan.ttf?dw7joc') format('truetype'),
    url('fonts/icostusan.woff?dw7joc') format('woff'),
    url('fonts/icostusan.svg?dw7joc#icostusan') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icostusan' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-hat-indicator .path1:before {
  content: "\e900";
  color: rgb(255, 255, 255);
}
.icon-hat-indicator .path2:before {
  content: "\e903";
  margin-left: -0.63671875em;
  color: ;
}
.icon-hat-indicator .path3:before {
  content: "\e904";
  margin-left: -0.63671875em;
  color: rgb(255, 255, 255);
}
.icon-finish-phone:before {
  content: "\e905";
  color: #fff;
}
.icon-rec:before {
  content: "\e908";
  color: #fff;
}
.icon-phone:before {
  content: "\e90c";
}
.icon-add-user:before {
  content: "\e901";
  color: #fff;
}
.icon-camera:before {
  content: "\e902";
  color: #fff;
}
.icon-chat:before {
  content: "\e906";
  color: #fff;
}
.icon-logo:before {
  content: "\e907";
  color: #fff;
}
.icon-hand:before {
  content: "\e909";
  color: #fff;
}
.icon-Mic:before {
  content: "\e90a";
  color: #fff;
}
.icon-triangle:before {
  content: "\e90b";
  color: #fff;
}
.icon-share:before {
  content: "\e90d";
  color: #fff;
}
