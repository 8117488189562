@use "sass:meta";


//label Extra small devices / Small devices / Medium devices
@media (max-width: $screen-md-max) {
  .block {
    &.border {
      border-radius: 0 0 $cardRadiusMobile $cardRadiusMobile;
    }
  }
  .section-head-bg-extension {
    top: -$cardRadiusMobile;
    height: $cardRadiusMobile;
  }
  .sticky {
    padding-top: 120px;
  }
  h2 {
    font-size: 48px;
  }
  .container {
    width: auto;
    padding: 0 24px;
  }
  //label MAIN BLOCK
  .main-block {
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-wrapper {
        width: 100%;
        font-size: 40px;
        line-height: 50px;
        &.desktop {
          display: none;
        }
        &.mobile {
          display: block;
        }
      }
    }
    .mainSlidingVertical {
      //display: inline-block;
      //margin-left: 10px;
      //width: 240px;
      width: 100%;
      height: 50px;
    }
  }

  //label FEATURES BLOCK
  .features-block {
    &-description {
      width: auto;
      margin-top: 30px;
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: auto;
      text-align: center;
      &-wrapper {
        margin-bottom: 60px;
        align-items: normal;
      }
    }
  }

  .featuresSlidingVertical {
    display: block !important;
    white-space: normal;
    height: 55px;
    line-height: 1;
    span {
      line-height: 1;
      display: block;
    }
  }

  #features {
    &-1, &-2, &-3  {
      width: auto;
    }
    &-1 {

    }
    &-2 {

    }
    &-3 {

    }
  }

  //label PROPOSAL BLOCK
  .proposal-block {
    padding-bottom: 150px;
    &-price {
      font-size: 60px;
      margin-top: 25px;
    }

    .description {
      width: auto;
      font-size: 24px;
      margin-top: 72px;
    }

    &-button {
      margin-top: 65px;
      font-size: 24px;
    }
  }

  //label CONTACT BLOCK
  .contact-block {
    .form {
      .button-wrapper {
        justify-content: center;
      }
      .send-button {
        width: 100%;
      }
    }
  }
  //label FOOTER BLOCK
  .footer-block {
    padding: 300px 0 25px;
    .contact-wrapper {
      .logo {
        margin-bottom: 42px;
      }

      .description {
        font-size: 24px;
      }
    }
  }


}

//label Medium devices / Large devices
@media (min-width: $screen-md-min) and (max-width: $screen-lg-max) {
  .container {
    width: 750px;
  }

  //label MAIN BLOCK
  .main-block {
    .title {
      &-wrapper {
        display: flex;
        font-size: 65px;
        line-height: 1.2em;
        &.desktop {
          display: block;
        }
        &.mobile {
          display: none;
        }
      }
    }
    .mainSlidingVertical {
      height: 120px;
      display: flex;
      justify-content: center;
      margin-left: 0;
      width: auto;
    }
  }

  //label FEATURES BLOCK
  .features-block {
    &-description {
      padding: 0 150px;
    }
    &-text {
      font-size: 72px;
    }
  }

  .featuresSlidingVertical {
    &.animated {
      bottom: -35px;
    }
  }
  #features-1 {
    width: 396px;
  }
  #features-2 {
    width: 212px;
  }
  #features-3 {
    width: 625px;
  }

}

//----------------------------------------------------------------------------------------------------

//label Extra small devices (portrait phones, less than 576px)
@media (max-width: $screen-xs-max) {
  .main-block {
    padding: 50px 0;
    .screen-wrapper {
      justify-content: space-between;
    }
  }

  .proposal-block {
    &-button {
      $padding: 20px;
      line-height: 30px;
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }

  #videoDesktop {
    display: none;
  }
  #videoMobile {
    display: block;
    max-width: 100%;
  }
}

//label Small devices (landscape phones, 576px and up)
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .container {
    width: auto;
  }

  //.mainSlidingVertical {
  //  width: 225px!important;
  //}
}

//label Medium devices (tablets, 768px and up)
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .container {
    width: 750px;
  }
}

//label Large devices (desktops, 992px and up)
@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
  .container {
    width: 970px;
  }
  .features-sliding-vertical-item {
    &.fs1 {
      width: 393px;
    }
    &.fs2 {
      width: 211px;
    }
    &.fs3 {
      width: 618px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: $screen-xl-min) {
  .container {
    //width: 1170px;
  }

  .features-sliding-vertical-item {
    &.fs1 {
      width: 546px;
    }
    &.fs2 {
      width: 292px;
    }
    &.fs3 {
      width: 858px;
    }
  }
}

//@mixin media($args...) {
//  @media (max-width: $screen-xs-max) {
//    @each $key, $value in $args {
//      $name: $value;
//    }
//  }
//}