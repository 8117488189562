.button {
  $borderSize: 3px;
  line-height: 90px - ($borderSize * 2);
  font-size: 32px;
  padding: 0 48px;
  border-radius: $borderRadius;
  border: $borderSize solid $colorRed;
  color: $colorRed;
  cursor: pointer;

  &.fill {
    background-color: $colorRed;
    color: $mainColorLightFont;
  }

  &.upper-cases {
    text-transform: uppercase;
  }

  &.center {
    text-align: center;
  }
}

//label Extra small devices (portrait phones, less than 576px)
@media (max-width: $screen-xs-max) {
  .button {
    font-size: 30px;
    padding: 0 25px;
  }
}
