:root {
  --color-text: $mainColorDarkFont;
  --color-text-heading: $mainColorDarkFont;
  --font-primary: sans-serif;
  --font-secondary: serif;
  --font-family: 'Exo2';
  --font-style: normal;
  --font-weight: 500;

  /* set base values */
  --text-base-size: 40px;
}

body {
  font-size: var(--text-base-size);
  font-family: var(--font-family);
  color: var(--color-text);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
}

h1, h2, h3, h4, form legend {
  color: var(--color-text-heading);
}

h1 {
  font-size: 100px;
}

h2 {
  font-size: 100px;
}

h3 {

}

h4 {

}

h5 {

}

h6 {

}

p {

}

//:root {
//  --font-primary: sans-serif;
//  --font-secondary: serif;
//
//  /* set base values */
//  --text-base-size: 1em;
//  --text-scale-ratio: 1.2;
//
//  /* type scale */
//  --text-xs: calc(1em / (var(--text-scale-ratio) * var(--text-scale-ratio)));
//  --text-sm: calc(1em / var(--text-scale-ratio));
//  --text-md: calc(1em * var(--text-scale-ratio));
//  --text-lg: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio));
//  --text-xl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
//  --text-xxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
//  --text-xxxl: calc(1em * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio) * var(--text-scale-ratio));
//}
//
//body {
//  font-size: var(--text-base-size);
//  font-family: var(--font-primary);
//  color: var(--color-text);
//}
//
//h1, h2, h3, h4, form legend {
//  color: var(--color-text-heading);
//  margin-bottom: var(--space-xxs);
//  line-height: var(--heading-line-height);
//}
//
///* text size */
//.text--xxxl {
//  font-size: var(--text-xxxl);
//}
//
//h1, .text--xxl {
//  font-size: var(--text-xxl);
//}
//
//h2, .text--xl {
//  font-size: var(--text-xl);
//}
//
//h3, .text--lg {
//  font-size: var(--text-lg);
//}
//
//h4, .text--md {
//  font-size: var(--text-md);
//}
//
//.text--sm, small {
//  font-size: var(--text-sm);
//}
//
//.text--xs {
//  font-size: var(--text-xs);
//}
//
//p {
//  line-height: var(--body-line-height);
//}