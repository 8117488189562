@font-face {
    font-family: 'Exo2';
    src: url('/src/fonts/Exo2-Regular.ttf')  format('truetype');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Exo2';
    src: url('/src/fonts/Exo2-Medium.ttf')  format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Exo2';
    src: url('/src/fonts/Exo2-Bold.ttf')  format('truetype');
    font-style: normal;
    font-weight: 700;
}