//label CONSOLE TEXT
.flip {
  height: 120px;
  overflow: hidden;

  div:first-child {
    animation: show 5s linear infinite;
  }
}

@keyframes show {
  0% {
    margin-top: -360px;
  }
  5% {
    margin-top: -240px;
  }
  33% {
    margin-top: -240px;
  }
  38% {
    margin-top: -120px;
  }
  66% {
    margin-top: -120px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -360px;
  }
}

.hidden {
  opacity: 0;
}

.console-container {
  text-align: center;
  color: white;
  margin: auto;
}

.console-underscore {
  color: white;
  display: inline-block;
  position: relative;
  top: -0.14em;
  left: 10px;
}

//label VERTICAL SLIDING
@mixin slidingVertical($height, $countWords, $speed:0.5s, $animation: topToBottom) {
  height: $height;
  display: flex;
  justify-content: center;

  span {
    animation: $animation $countWords * $speed linear infinite 0s;
    color: $mainColorLightFont;
    opacity: 0;
    overflow: hidden;
    position: absolute;

    @for $i from 1 through $countWords {
      &:nth-child(1n + #{$i}) {
        animation-delay: $i * $speed;
      }
    }
  }
}

@mixin features($width) {
  position: relative;
  width: $width;
  display: inline-flex;
  overflow: hidden;
}

#features-1 {
  @include features(546px);
}

#features-2 {
  @include features(292px);

}

#features-3 {
  @include features(858px);

}

.mainSlidingVertical {
  //@include slidingVertical(120px, 5, 0.8s, topToBottomMain);
  span {
    color: $mainColorLightFont;
  }
}

.featuresSlidingVertical {
  white-space: nowrap;
  &.animated {
    bottom: -10px;
    @include slidingVertical(100px, 2, 1.2s);
    span {
      left: 0;
      right: 0;
    }
  }
}
@keyframes topToBottomMain {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  15% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  35% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  35% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  40% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}