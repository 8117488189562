//MAIN COLORS
$colorWhite: #ffffff;
$colorBlack: #000000;
$colorRed: #FC573B;
$colorGray: #4B4C4D;
$colorDarkGray: #3D3F40;
$colorLightBlack: #1E2021;

//FONTS COLORS
$mainColorDarkFont: $colorBlack;
$mainColorLightFont: $colorWhite;

$cardRadius: 80px;
$cardRadiusMobile: 20px;
$borderRadius: 20px;

//BREAKPOINTS
$screen-xs-min: 320px;
$screen-xs-max: 575.98px;

$screen-sm-min: 576px;
$screen-sm-max: 767.98px;

$screen-md-min: 768px;
$screen-md-max: 991.98px;

$screen-lg-min: 992px;
$screen-lg-max: 1199.98px;

$screen-xl-min: 1200px;
$screen-xl-max: 1900px;